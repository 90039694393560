import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {goBack, goTo, toPriceStr} from '@/utils';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Text from '@/components/basic/text';
import TransferBalance from './transfer-balance';
import Spin from '@/components/basic/spin';
import RechargeButton from '@/components/business/recharge-button';
import globalStore from '@/services/global.state';
import TransferAmount from './transfer-amount';
import {IUserInfo, postUserInfo} from '@/services/global.service';
import {plus, times} from '@components/utils/number-precision';
import {BasicObject} from '@/types';
import {onTransfer} from './transfer-service';
const Transfer = () => {
  const {i18n} = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [amount, setAmount] = React.useState('');
  const [user, setUser] = React.useState<IUserInfo | BasicObject>({});
  React.useEffect(() => {
    getUserInfo().then();
  }, []);

  const getUserInfo = async (refresh = true) => {
    try {
      if (refresh) {
        setLoading(true);
      }
      const res = await postUserInfo();
      setUser(res);
    } finally {
      setLoading(false);
    }
  };

  const handleGotoRecords = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: 'Home'});
      return;
    }
    goTo('TransferRecords');
  };

  const actualReceived = React.useMemo(() => {
    const max = user?.canWithdrawAmount || 0;
    if (
      user!.withdrawalFreeConfigs &&
      user!.withdrawalFreeConfigs.length > 0 &&
      max > 0
    ) {
      if (amount && amount <= max) {
        const amountToNumber = Number(amount);
        if (!isNaN(amountToNumber)) {
          const config = user!.withdrawalFreeConfigs.find(
            (item: {maxValue: number; minValue: number}) => {
              return (
                item.maxValue >= amountToNumber &&
                item.minValue <= amountToNumber
              );
            },
          );
          if (config) {
            return times(plus(1, config.pct), amountToNumber);
          }
        }
      }
    }
    return 0;
  }, [amount, user]);

  const onSubmit = async () => {
    setLoading(true);
    onTransfer(Number(amount))
      .then(() => {
        getUserInfo(false);
        setAmount('');
        globalStore.globalSucessTotal(i18n.t('transfer-page.tip.success'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: 'Home'});
      return;
    }
    getUserInfo(true);
  };

  return (
    <div className="w-full h-[100vh] flex flex-col bg-[var(--BG)]">
      <DetailNavTitle
        onBack={goBack}
        hideAmount
        title={i18n.t('label.transfer')}
        rightNode={
          <div
            onClick={handleGotoRecords}
            className="text-t1 text-sm font-bold">
            {i18n.t('label.history')}
          </div>
        }
      />
      <Spin loading={loading} style={[theme.flex.flex1, theme.flex.col]}>
        <div className="flex-1 p-3 flex flex-col gap-3">
          <TransferBalance
            // onGotoRecords={handleGotoRecords}
            onRefresh={handleRefresh}
            balance={user?.canWithdrawAmount || 0}
          />
          <TransferAmount
            // receiveAmount={actualReceived}
            inputAmount={amount}
            balance={user?.canWithdrawAmount || 0}
            onInputChange={setAmount}
          />
        </div>
        <div className="bg-card">
          <div className="flex flex-row items-center justify-center px-3 pt-3">
            <Text color={'#5F6975'} size="medium">
              {i18n.t('transfer-page.label.willGet')}:{' '}
            </Text>
            <Text
              color={theme.basicColor.primary}
              fontFamily="fontInterBold"
              fontSize={20}>
              {toPriceStr(actualReceived, {
                thousands: true,
                spacing: false,
                fixed: 2,
                currency: globalStore.currency,
              })}
            </Text>
          </div>
          <RechargeButton
            onRecharge={onSubmit}
            disabled={Boolean(!actualReceived)}
            type="linear-primary"
            text={i18n.t('label.confirm')}
          />
        </div>
      </Spin>
    </div>
  );
};

export default Transfer;
