import theme from '@/style';
import React from 'react';
import {FlatList, ListRenderItemInfo, StyleSheet, View} from 'react-native';
import {useInnerStyle} from './vip.hooks';
import Text from '@/components/basic/text';
import {VipRenderType} from '@/components/business/vip';
import LazyImage from '@basicComponents/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {
  currentPointIcon,
  firstPointIcon,
  luckyspinIcon,
  moneyIcon,
  nextPointIcon,
  reachPointIcon,
  unreachPointIcon,
} from './vip.variable';
import {toPriceStr} from '@/utils';
import {IVipConfigItem, IVipItem} from '@/services/global.service';
import LinearGradient from '@basicComponents/linear-gradient';
import {useTranslation} from 'react-i18next';

export interface VipTableListProps {
  cards: VipRenderType[];
  vipList: IVipItem[];
  vipConfigList: IVipConfigItem[];
  onCheck?: (index: number) => void;
  checkIndex?: number;
  currentLevel: number;
}

const VipTableList: React.FC<VipTableListProps> = ({
  cards,
  vipList,
  onCheck,
  checkIndex,
  vipConfigList,
  currentLevel,
}) => {
  const {tableStyle} = useInnerStyle();
  const {i18n} = useTranslation();
  const renderVipTableItem = ({item, index}: ListRenderItemInfo<IVipItem>) => {
    return (
      <NativeTouchableOpacity onPress={() => onCheck?.(index)}>
        <View style={styles.item}>
          <View
            style={[
              tableStyle.points,
              theme.flex.row,
              theme.flex.start,
              theme.flex.centerByCol,
              styles.points,
            ]}>
            <LazyImage
              width={18}
              height={18}
              radius={9}
              occupancy="#0000"
              imageUrl={
                item.level === 0 && item.level < currentLevel
                  ? firstPointIcon
                  : item.level < currentLevel
                  ? reachPointIcon
                  : item.level === currentLevel
                  ? currentPointIcon
                  : item.level === currentLevel + 1
                  ? nextPointIcon
                  : unreachPointIcon
              }
            />
          </View>
          <View
            style={[
              theme.flex.center,
              tableStyle.level,
              tableStyle.itemLevel,
              {
                backgroundColor: index === checkIndex ? '#4C3922' : '#373B44',
              },
            ]}>
            {cards[index].smallFn(25)}
            {currentLevel === index ? (
              <View style={{marginTop: 2}}>
                <Text fontSize={10} fontWeight={'700'} textAlign="center">
                  Current
                </Text>
                <Text fontSize={10} fontWeight={'700'} textAlign="center">
                  Level
                </Text>
              </View>
            ) : null}
            <Text></Text>
          </View>
          <View
            style={[
              theme.flex.center,
              theme.flex.flex1,
              tableStyle.itemReward,
              theme.padding.lrm,
              {
                backgroundColor: index === checkIndex ? '#3E3024' : '#262B33',
              },
            ]}>
            <View
              style={[
                theme.flex.between,
                theme.fill.fillW,
                theme.padding.lrm,
                theme.flex.row,
              ]}>
              <View
                style={[
                  theme.flex.col,
                  theme.flex.centerByCol,
                  tableStyle.itemRewardItem,
                ]}>
                <View style={[theme.flex.row, theme.flex.centerByCol]}>
                  <LazyImage
                    imageUrl={moneyIcon}
                    occupancy="#0000"
                    width={theme.iconSize.l}
                    height={theme.iconSize.l}
                  />
                  <Text
                    style={[theme.margin.leftxxs]}
                    fontSize={theme.fontSize.s}
                    blod
                    accent>
                    {i18n.t('vip.table.bouns')}
                  </Text>
                </View>
                <Text blod fontSize={theme.fontSize.l}>
                  {toPriceStr(vipConfigList[index]?.amount || 0, {
                    fixed: 0,
                    thousands: true,
                  })}
                </Text>
              </View>
              <View
                style={[
                  theme.flex.col,
                  theme.flex.centerByCol,
                  tableStyle.itemRewardItem,
                ]}>
                <View style={[theme.flex.row, theme.flex.centerByCol]}>
                  <LazyImage
                    imageUrl={luckyspinIcon}
                    occupancy="#0000"
                    width={theme.iconSize.l}
                    height={theme.iconSize.l}
                  />
                  <Text
                    style={[theme.margin.leftxxs]}
                    fontSize={theme.fontSize.s}
                    blod
                    accent>
                    {i18n.t('vip.table.spin')}
                  </Text>
                </View>
                <Text blod fontSize={theme.fontSize.l}>
                  ×{vipConfigList[index]?.spin}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </NativeTouchableOpacity>
    );
  };

  return (
    <View style={[theme.flex.flex1, theme.margin.topl, theme.padding.lrl]}>
      <LinearGradient
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}
        colors={['#3E434F', '#3E434F']}
        style={[tableStyle.header, theme.flex.row]}>
        <View style={[tableStyle.points]} />
        <View style={[theme.flex.center, tableStyle.level]}>
          <Text second fontSize={theme.fontSize.m}>
            {i18n.t('vip.table.level')}
          </Text>
        </View>
        <View style={[theme.flex.center, theme.flex.flex1]}>
          <Text second fontSize={theme.fontSize.m}>
            {i18n.t('vip.table.reward')}
          </Text>
        </View>
      </LinearGradient>
      <FlatList
        style={{
          backgroundColor: 'var(--card)',
        }}
        data={vipList}
        renderItem={renderVipTableItem}
        ItemSeparatorComponent={ItemSeperator}
      />
    </View>
  );
};

const ItemSeperator = () => {
  return (
    <View style={styles.lineContainer}>
      <View style={[styles.line1]} />
    </View>
  );
};
const styles = StyleSheet.create({
  points: {
    alignItems: 'center',
  },
  item: {
    height: 64,
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'flex-start',
  },
  border: {
    borderBottomColor: '#404550',
    borderBottomWidth: 1,
  },
  lineContainer: {
    marginLeft: 22,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    zIndex: 1,
    height: 1,
    backgroundColor: '#404550',
  },
  line1: {
    height: 37,
    position: 'absolute',
    top: -20,
    left: -13,
    zIndex: -1,
    width: 1,
    backgroundColor: '#404550',
  },
});

export default VipTableList;
